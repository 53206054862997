<script setup>
import { useCartStore } from '~/stores/cart';

const emit = defineEmits(["onAdd"]);

const cartStore = useCartStore();

const onAdd = (item) => {
    emit("onAdd", item);
}

</script>

<template>
    <div v-if="cartStore.filteredUpsales && cartStore.filteredUpsales.length > 0" class="upsale">
        <div class="upsale-title">
            <h3>Добавить к заказу</h3>
        </div>
        <div class="upsale-items">
            <div v-for="item in cartStore.filteredUpsales" @click="onAdd(item.product)" class="upsale-item">
                <div class="image ratio ratio-1x1" :style="{ 'background-image': `url(${item.product.image_url})` }">
                </div>
                <div class="name">{{ item.product.name_with_size }}</div>
                <div class="info">
                    <div class="price">{{ item.product.price }}₽</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.upsale {
    margin-top: 14px;
    margin-left: -10px;
    margin-right: -10px;

    .upsale-title {
        display: block;
        padding-top: 8px;
        padding-left: 16px;

        h3 {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .upsale-items {
        display: flex;
        flex-direction: row;
        gap: 8px;
        overflow-x: scroll;
        padding-bottom: 20px;
        padding-left: 14px;
        padding-right: 14px;

        .upsale-item {
            background-color: #fff;
            border-radius: 20px;

            padding: 4px;

            display: flex;
            flex-direction: column;
            box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.05);

            user-select: none;
            transform: scale(1);
            transition: all 0.3s;

            cursor: pointer;

            .image {
                background-size: cover;
                border-radius: 20px;
                background-position: 50% 50%;
                width: 160px;
            }

            .name {
                font-size: 11px;
                font-weight: 500;
                text-overflow: ellipsis;
                text-align: center;
                margin-top: 10px;

                overflow: hidden;
                line-height: 1;
                color: #000;

                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

                @media (max-width: 900px) {
                    font-size: 12px;
                }

            }

            .info {
                border-radius: 50px;
                padding: 8px 20px;
                text-align: center;
                margin-top: auto;

                .price {
                    font-size: 12px;
                    font-weight: 500;
                    color: #000;
                }
            }
        }
    }


}
</style>